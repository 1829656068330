html, body {
    margin: 0;
    display: flex;
    flex-direction: column;
    background: #F1F2F8;
    font-family: 'Roboto', sans-serif;
    height: 100vh;
}

#root {
    height: 100%;
}

.conversation {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: flex-start;
    margin-bottom: 100px;
    padding: 150px 80px 40px;
    height: calc(100% - 120px);

    /* On mobile, padding-left and right should be realler smaller */
    @media (max-width: 768px) {
        padding: 150px 20px 40px;
    }
}


$dot-width: 10px;
$dot-color: #3b5998;
$speed: 1.5s;

@keyframes blink {
    0% {
        opacity: .1;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .1;
    }
}

.message-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }

    &.other {
        justify-content: flex-end;
    }
}

.message {
    background: #FFF;
    padding: 15px 25px;
    border-radius: 10px 10px 2px 10px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;

    > * {
        margin: 10px 0;
    }

    &.other {
        border-radius: 10px 10px 10px 2px;
        // Gris foncé
        background: #3b5998;

        color: #FFF;
        align-self: flex-end;
    }

    &.typing {
        position: relative;
        display: flex;
        flex-direction: row;
        /** align everything to the left */
        justify-content: flex-start;



        span {
            animation: blink $speed infinite;
            animation-fill-mode: both;
            height: $dot-width;
            width: $dot-width;
            background: $dot-color;
            border-radius: 50%;
            margin-left: 5px;

            &:nth-child(2) {
                animation-delay: .2s;
            }

            &:nth-child(3) {
                animation-delay: .4s;
            }
        }
    }
}

input, select, textarea {
    font-size: 16px;
    width: 100%;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    -webkit-appearance: none;

    &:focus {
        outline: 1px solid #CA524D;
        border-color: #CA524D;
    }
}

button.button {
    background: #CA524D;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 7px;
    border: none;
    cursor: pointer;
    transition: 0.2s;
    min-width: 150px;

    &:hover {
        background: #B03A35;
    }
}

* {
    box-sizing: border-box;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px auto;
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;

    button {
        margin: 0 10px;
    }
}

.all-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
        width: 200px;
        height: 200px;
        margin-bottom: 20px;
        border-radius: 20px;
    }
}