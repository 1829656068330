.hero-selector {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #FFF;
  align-content: flex-end;
  justify-content: space-between;

  .hero {
    width: 80px;
    height: 80px;
    margin: 5px;
    padding: 0;

    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 11px;
    background: #CA524D;
    color: #FFF;
    font-weight: bold;
    border-radius: 7px;
    border: 0;

    &:hover {
      transform: scale(1.1);
    }

    img {
      background: #FFF;
      width: 60px;
      height: 50px;
      display: block;
      object-fit: cover;
      flex-shrink: 0;
    }
  }
}