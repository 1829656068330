.header {
  /** Grey */
  background-color: #FFF;
  /** Full width */
  width: 100%;
  height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 80px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  /** Qui se voit moins */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

  .logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}